import { FormInstance, FormRules, ElMessage, ElMessageBox } from 'element-plus'

import service from './service'

import { ethers } from 'ethers'

export async function buildContract(
  blockchainId,
  smartContractAddress,
  account
) {
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })

  if (accounts[0].toLowerCase() !== account.toLowerCase()) {
    ElMessage.error(
      'Sorry, the current use wallet and login wallet do not match'
    )
    throw new Error(
      'Sorry, the current use wallet and login wallet do not match'
    )
  }

  const blockchain = await getBlockchain(blockchainId)

  console.info('blockchain:', blockchain)

  const currChainId = await ethereum.request({ method: 'eth_chainId' })

  // @ts-ignore
  if (parseInt(currChainId, 16) !== blockchain.chainId) {
    // example of switching or adding network with Harmony Mainnet
    const switchNetwork = async () => {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [
            { chainId: '0x' + parseInt(blockchain.chainId).toString(16) },
          ],
        })
      } catch (switchError) {
        // 4902 error code indicates the chain is missing on the wallet
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x' + parseInt(blockchain.chainId).toString(16),
                  chainName: blockchain.name,
                  nativeCurrency: {
                    name: blockchain.currency,
                    symbol: blockchain.currency,
                    decimals: 18,
                  },
                  rpcUrls: [blockchain.publicRpcUrl],
                  blockExplorerUrls: [blockchain.blockBrowserUrl],
                },
              ],
            })
          } catch (error) {
            console.error(error)
          }
        }
      }
    }

    await switchNetwork()
  }

  const provider = new ethers.BrowserProvider(window.ethereum)

  const signer = await provider.getSigner()

  const smartContract = await getSmartContractByAddress(
    smartContractAddress,
    blockchainId
  )

  const contract = new ethers.Contract(
    smartContract.address,
    smartContract.contractBinary,
    signer
  )
  console.info(contract)
  return contract
}

async function getBlockchain(id) {
  const { result } = await service.get('/mgn/blockchain/queryById', {
    params: { id },
  })
  return result
}

async function getSmartContractByAddress(address, blockchainId) {
  const { result } = await service.get(
    '/mgn/smartContract/getSmartContractByContractAddress',
    { params: { address, blockchainId } }
  )

  return result
}

export function exceptionHandling(e, t) {
  console.error('Error details:', e) // 输出完整的错误详情

  if (!e.code) {
    // 检查网络相关的错误
    if (
      e.message.includes('Failed to fetch') ||
      e.message.includes('Network Error')
    ) {
      ElMessage.error(
        'There was a problem connecting to the network. Please check your internet connection.'
      )
      return
    } else {
      ElMessage.error(e.message)
      return
    }
  }

  const error = e.info.error

  console.info('error:', error)
  // RPC错误处理
  if (error.code && error.code === 4001) {
    // 用户拒绝了交易请求
    ElMessage.error('The transaction request was rejected by the user.')
  } else if (error.code && error.code === -32000) {
    // 服务器拒绝了请求，可能是因为gas价格太低
    ElMessage.error(
      'The server rejected the request, possibly due to low gas price.'
    )
  } else if (e.reason) {
    // 合约抛出的错误
    ElMessage.error(`An error occurred in the contract execution: ${e.reason}.`)
  } else {
    // 其他类型的错误
    ElMessage.error(e.message || 'An unknown error occurred.')
  }
}
